import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faBullseye } from "@fortawesome/free-solid-svg-icons";
import { motion } from "framer-motion";

const BgImages =
  "https://s3.ap-southeast-3.amazonaws.com/staticwebcoid/bg1.jpg";

const BgImages2 =
  "https://s3.ap-southeast-3.amazonaws.com/staticwebcoid/bg2.jpg";

export default function AboutUs() {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <div className="w-screen">
      <div className="w-full grid grid-cols-1 lg:grid-cols-2 py-10 color-dark-blue px-4 md:px-20 gap-10">
        <div className="w-full xl:pr-40 flex flex-col gap-6 text-justify">
          <p className="text-2xl font-bold">Tentang PT. HS Budiman 45</p>
          <p className="text-md font-base">
            Dari Bus Reguler, Pariwisata, Shuttle & Taksi Budiman, PO HS Budiman
            45 adalah kelompok usaha yang siap melayani kebutuhan transportasi
            anda yang berpusat di Kota Tasikmalaya Jawa Barat, Indonesia. PT HS
            Budiman 45 berdiri pada tahun 1992, dan terus meningkatkan
            pelayanannya hingga sekarang.
          </p>
        </div>
        <img
          className="w-full rounded-lg"
          src={BgImages}
          alt="background images"
        />
      </div>
      <div className="w-full grid grid-cols-1 lg:grid-cols-2 py-10 px-4 md:px-20 gap-10">
        <img
          className="w-full rounded-lg"
          src={BgImages2}
          alt="background images"
        />
        <div className="w-full xl:pl-40 flex flex-col gap-6 text-justify">
          <p className="text-2xl font-bold">Perkembangan PT.HS Budiman 45</p>
          <p className="text-md font-base">
            Seiring dengan perkembangannya perusahaan bertransformasi menjadi
            PT. HS. Budiman 45, dengan mengembangkan usaha selain Bus Cepat
            Budiman (Reguler) dengan berbagai Kelas Mulai dari Premiere Class,
            First Class, Executive dan Bisnis Class, Pariwisata , juga tersedia
            Shuttle Budiman (seperti Toyota Hi Ace, Mercedes Benz Sprinter) ,
            Taksi Budiman, dan yang terbaru Cargo Budiman. Kami berkomitmen
            untuk terus menjaga kualitas pelayanan dalam setiap bisnis yang kami
            jalani. Misalnya, strategi penempatan armada serta kemudahan
            mendapatkannya
          </p>
        </div>
      </div>
      <div className="grid grid-cols-2 relative -top-32 px-20"></div>
      <div className="py-10 flex color-dark-blue flex-col gap-4">
        <div className="w-1/3 flex flex-col gap-4 mx-auto text-center">
          <p className="text-2xl font-semibold">Visi Kami</p>
          <FontAwesomeIcon icon={faEye} className="h-10" />
        </div>
        <motion.div
          whileHover={{
            scale: 1.05,
            transition: { duration: 1 },
          }}
          className="width-card bg-white mx-auto p-8 flex flex-col justify-center items-center gap-4 shadow-xl border-2 text-justify rounded-md"
        >
          <p className="text-lg font-light">
            Menjadi mitra silaturahmi yang terikat, amanah bagi seluruh
            pelanggan dengan memberikan service value yang berkualitas.
          </p>
        </motion.div>
      </div>
      <div className="py-10 flex flex-col gap-4 color-dark-blue">
        <div className="w-full flex flex-col gap-4 mx-auto text-center">
          <p className="text-2xl font-semibold">Misi Kami</p>
          <FontAwesomeIcon icon={faBullseye} className="h-10" />
        </div>
        <div className="w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-10 md:px-10 py-5">
          <motion.div
            whileHover={{
              scale: 1.05,
              transition: { duration: 1 },
            }}
            className="width-card bg-white mx-auto py-10 px-5 flex flex-col justify-center items-center gap-4 shadow-xl border-2 text-justify rounded-md"
          >
            <p className="text-lg font-light">
              Memberikan pelayanan prima yang optimal kepada pemakai jasa
              angkutan.
            </p>
          </motion.div>
          <motion.div
            whileHover={{
              scale: 1.05,
              transition: { duration: 1 },
            }}
            className="width-card bg-white mx-auto py-10 px-5 flex flex-col justify-center items-center gap-4 shadow-xl border-2 text-justify rounded-md"
          >
            <p className="text-lg font-light">
              Mengelola perusahaan secara profesional agar sehat, tangguh dan
              berkembang.
            </p>
          </motion.div>
          <motion.div
            whileHover={{
              scale: 1.05,
              transition: { duration: 1 },
            }}
            className="width-card bg-white mx-auto py-10 px-5 flex flex-col justify-center items-center gap-4 shadow-xl border-2 text-justify rounded-md"
          >
            <p className="text-lg font-light">
              Melakukan pembinaan organisasi dan karyawan secara sistematis dan
              terarah.
            </p>
          </motion.div>
        </div>
      </div>
      <div className="w-full lg:w-3/4 mx-auto">
        <p className="text-2xl font-semibold p-2">Armada Kami</p>
        <Carousel
          responsive={responsive}
          infinite={true}
          swipeable={true}
          draggable={true}
          autoPlaySpeed={2000}
        >
          <div className="w-full p-2 text-center cursor-pointer">
            <motion.img
              whileHover={{
                scale: 1.05,
                transition: { duration: 0.5 },
              }}
              className="h-64 lg:h-60 lg:w-auto rounded-lg"
              src="https://s3.ap-southeast-3.amazonaws.com/staticwebcoid/PremiereClass1.jpg"
            />
            <div className="w-full text-justify py-2">
              <p className="text-sm font-light text-slate-600 py-2">
                Premiere Class
              </p>
            </div>
          </div>
          <div className="w-full p-2 text-center cursor-pointer">
            <motion.img
              whileHover={{
                scale: 1.05,
                transition: { duration: 0.5 },
              }}
              className="h-64 lg:h-60 lg:w-auto rounded-lg"
              src="https://bdmnstatic.s3.ap-southeast-3.amazonaws.com/static/thumbnails/2021/12/17/gtwbistebcjf4btknrcjiu.jpg"
            />
            <div className="w-full text-justify py-2">
              <p className="text-sm font-light text-slate-600 py-2">
                First Class
              </p>
            </div>
          </div>
          <div className="w-full p-2 text-center cursor-pointer">
            <motion.img
              whileHover={{
                scale: 1.05,
                transition: { duration: 0.5 },
              }}
              className="h-64 lg:h-60 lg:w-auto rounded-lg"
              src="https://bdmnstatic.s3.ap-southeast-3.amazonaws.com/static/thumbnails/2021/12/17/czekh6dldpdbuqmmsn9tij.jpg"
            />
            <div className="w-full text-justify py-2">
              <p className="text-sm font-light text-slate-600 py-2">
                Executive Class
              </p>
            </div>
          </div>
          <div className="w-full p-2 text-center cursor-pointer">
            <motion.img
              whileHover={{
                scale: 1.05,
                transition: { duration: 0.5 },
              }}
              className="h-64 lg:h-60 lg:w-auto rounded-lg"
              src="https://bdmnstatic.s3.ap-southeast-3.amazonaws.com/static/thumbnails/2021/12/17/z5qbzxfecp2jl3v72xmqbc.jpg"
            />
            <div className="w-full text-justify py-2">
              <p className="text-sm font-light text-slate-600 py-2">
                Bisnis Class
              </p>
            </div>
          </div>
          <div className="w-full p-2 text-center cursor-pointer">
            <motion.img
              whileHover={{
                scale: 1.05,
                transition: { duration: 0.5 },
              }}
              className="h-64 lg:h-60 lg:w-auto rounded-lg"
              src="/assets/5.jpg"
            />
            <div className="w-full text-justify py-2">
              <p className="text-sm font-light text-slate-600 py-2">
                Pariwisata Class
              </p>
            </div>
          </div>
        </Carousel>
      </div>
      <div className="bg-gray-100"></div>
    </div>
  );
}
